import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button, Row, Col, Table, Space, Modal, message, Form, Input, InputNumber, Typography } from 'antd';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import Confirmation from './confirmation';
import { useNavigate, useLocation } from 'react-router-dom';
import { DashboardOutlined, CalendarOutlined, HistoryOutlined } from '@ant-design/icons';
const { Header, Sider, Content, Footer } = Layout;
const { Title } = Typography;

// Declare apiUrl at the top of your component file
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3002';

console.log('API URL:', apiUrl);

const Booking = () => {
  const { logout, user, } = useAuth0();
  const { name: userName, email: userEmail } = user;
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [date, setDate] = useState(new Date());
  const [activeView, setActiveView] = useState('dashboard');
  const [weeklySlots, setWeeklySlots] = useState({});
  const [displayedDays, setDisplayedDays] = useState([]);
  const [filteredSlots, setFilteredSlots] = useState({});
  const [boundaryDate, setBoundaryDate] = useState(null);
  const [currency, setCurrency] = useState('USD'); // Currency for selected slot
  const [paymentAmount, setPaymentAmount] = useState(0); // Amount to be paid
  const [showModal, setShowModal] = useState(false); // Controls modal visibility
  const [errors, setErrors] = useState({}); // Error messages for form validation
  const [name, setName] = useState(userName ?? ''); // Student name
  const [email, setEmail] = useState(userEmail ?? ''); // Student email
  const [phone, setPhone] = useState(''); // Student phone number
  const [activeBookings, setActiveBookings] = useState([]);
  const [historyBookings, setHistoryBookings] = useState([]);
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [couponCode, setCouponCode] = useState('');
  const [discountedAmount, setDiscountedAmount] = useState(null);
  const [couponError, setCouponError] = useState('');
  const [discountPercent, setDiscountPercent] = useState(0);
  const [isBookingConfirmed, setIsBookingConfirmed] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [cancellingId, setCancellingId] = useState(null);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState('paid'); // Default filter set to 'paid'
  const [credits, setCredits] = useState(0);
  const [creditsExpiry, setCreditsExpiry] = useState(null);
  const [hasValidCredits, setHasValidCredits] = useState(false);
  const [bulkPackageDetails, setBulkPackageDetails] = useState(null);
  const [isBulkBooking, setIsBulkBooking] = useState(false);

  // Add simple hash navigation for dashboard
  useEffect(() => {
    if (!location.hash) {
      navigate('/booking#dashboard', { replace: true });
    }
  }, []);

  // Sidebar items
  const menuItems = [
    {
      key: '1',
      label: 'Dashboard',
      onClick: () => navigate('/booking#dashboard'),
      icon: <DashboardOutlined />,
    },
    {
      key: '2',
      label: 'Active Classes',
      onClick: () => navigate('/booking#activeclasses'),
      icon: <CalendarOutlined />,
    },
    {
      key: '3',
      label: 'History',
      onClick: () => navigate('/booking#history'),
      icon: <HistoryOutlined />,
    },
    {
      key: '4',
      label: 'Account',
      onClick: () => navigate('/booking#account'),
      icon: <DashboardOutlined />,
    },
    {
      key: '5',
      label: 'Contact Us',
      onClick: () => setActiveView('contactUs')
    },
    {
      key: '6',
      label: 'Terms & Conditions',
      onClick: () => setActiveView('termsConditions')
    },
    {
      key: '7',
      label: 'Logout',
      onClick: () => logout({ returnTo: window.location.origin })
    },
  ];

  const calculateServiceFee = (basePrice) => {
    if (!basePrice) return 0;
    return (basePrice * 0.10).toFixed(2); // 10% of the base price
  };

  const handleSlotSelect = (slot) => {
    // Define updatedSlot here before using it
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Set currency and amount based on timezone
    let slotCurrency, slotAmount;
    if (localTimeZone.includes('Asia/')) {
      slotCurrency = 'INR';
      slotAmount = 999;
    } else {
      slotCurrency = 'USD';
      slotAmount = 24.99;
    }

    const updatedSlot = {
      ...slot,
      currency: slotCurrency,
      paymentAmount: slotAmount
    };

    setSelectedSlot(updatedSlot);
    setCurrency(slotCurrency);
    setPaymentAmount(slotAmount);
    setShowModal(true);
  };

  // Fetch slots once and cache them
  const fetchWeeklySlots = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/slots`);
      const allSlots = await response.json();

      const slotsByDay = {};
      const today = dayjs();

      // Only process slots that are in the future
      allSlots.forEach(slot => {
        const slotDate = dayjs(slot.dateTime);
        if (slotDate.isAfter(today)) {
          const dayKey = slotDate.format('YYYY-MM-DD');
          if (!slotsByDay[dayKey]) {
            slotsByDay[dayKey] = [];
          }
          slotsByDay[dayKey].push({
            ...slot,
            formattedTime: slotDate.format('h:mm A')
          });
        }
      });

      // Sort slots within each day
      Object.keys(slotsByDay).forEach(day => {
        slotsByDay[day].sort((a, b) =>
          dayjs(a.dateTime).diff(dayjs(b.dateTime))
        );
      });

      setWeeklySlots(slotsByDay);
    } catch (err) {
    }
  };

  useEffect(() => {
    fetchWeeklySlots();
  }, []);

  // Generate next 5 days starting from `startDate`, but include current day as unavailable
  const generateNextFiveDays = (startDate) => {
    const days = [];
    for (let i = 0; i < 5; i++) {
      days.push(dayjs(startDate).add(i, 'day').format('YYYY-MM-DD'));
    }
    return days;
  };

  // Handle boundary-based date updates
  const handleDateSelect = (selectedDate) => {
    setDate(selectedDate);

    const newDisplayedDays = generateNextFiveDays(selectedDate);
    setDisplayedDays(newDisplayedDays);
    setBoundaryDate(dayjs(newDisplayedDays[newDisplayedDays.length - 1]));

    const newFilteredSlots = {};
    newDisplayedDays.forEach(day => {
      newFilteredSlots[day] = weeklySlots[day] || [];
    });
    setFilteredSlots(newFilteredSlots);
  };

  useEffect(() => {
    const initialDisplayedDays = generateNextFiveDays(date);
    setDisplayedDays(initialDisplayedDays);
    setBoundaryDate(dayjs(initialDisplayedDays[initialDisplayedDays.length - 1]));

    const slotsForInitialRange = {};
    initialDisplayedDays.forEach(day => {
      slotsForInitialRange[day] = weeklySlots[day] || [];
    });
    setFilteredSlots(slotsForInitialRange);
  }, [weeklySlots]);

  const checkOrCreateStudentProfile = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/check-or-create-student`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          email: email,
        }),
      });

      if (!response.ok) {
        const errorData = await response.text();
        throw new Error('Failed to create student profile');
      }

      const data = await response.json();
      return data.success;
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    if (user) {
      checkOrCreateStudentProfile();
    }
  }, [user]);

  const handleCouponApply = async () => {
    if (!couponCode) {
      setCouponError('Please enter a coupon code');
      return;
    }

    setCouponError('');
    setDiscountedAmount(null);
    setDiscountPercent(0);
    
    try {
      console.log('Applying coupon:', couponCode); // Debug log
      
      const response = await fetch(`${apiUrl}/api/coupons/validate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          code: couponCode // Changed from couponCode to code to match production
        })
      });

      const data = await response.json();
      console.log('Coupon response:', data); // Debug log

      if (!response.ok) {
        setCouponError(data.error || 'Invalid coupon code');
        return;
      }

      // Calculate discount on frontend like in production
      const baseAmount = isBulkBooking ? bulkPackageDetails?.price : paymentAmount;
      const discount = (baseAmount * data.discount) / 100;
      const finalAmount = baseAmount - discount;
      
      setDiscountedAmount(finalAmount);
      setDiscountPercent(data.discount);
      setCouponError('');
    } catch (error) {
      console.error('Error applying coupon:', error);
      setCouponError('Error validating coupon');
      setDiscountedAmount(null);
      setDiscountPercent(0);
    }
  };

  // Update calculateTotalAmount to use the service fee function
  const calculateTotalAmount = (baseAmount) => {
    if (!baseAmount) return 0;
    const serviceFee = parseFloat(calculateServiceFee(baseAmount));
    const razorpayFee = parseFloat((baseAmount * 0.024).toFixed(2));
    return (parseFloat(baseAmount) + serviceFee + razorpayFee).toFixed(2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedSlot) {
      setErrors({ form: 'Please select a class slot' });
      return;
    }

    try {
      // First, fetch the latest student profile to check credits
      const studentResponse = await fetch(`${apiUrl}/api/student-profile?email=${email}`);
      const studentData = await studentResponse.json();

      // Check if student has valid credits
      const hasValidCredits = studentData.classCredits > 0 && 
        new Date(studentData.classCreditsExpiry) > new Date();

      if (hasValidCredits) {
        // Use credits instead of payment
        const bookingResponse = await fetch(`${apiUrl}/api/book-with-credits`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            studentEmail: email,
            studentName: name,
            studentPhone: phone,
            slotId: selectedSlot._id,
            studentId: studentData._id,
            currency: 'INR', // Add default currency
            paymentMethod: 'credits'
          }),
        });

        if (!bookingResponse.ok) {
          const errorData = await bookingResponse.json();
          throw new Error(errorData.error || 'Failed to book class with credits');
        }

        const result = await bookingResponse.json();
        message.success('Class booked successfully using credits!');
        
        if (!result.success) {
          throw new Error(result.error || 'Failed to book class with credits');
        }

        // Handle booking confirmation with credits
        const confirmationDetails = {
          bookingId: result.booking.bookingId,
          name: name,
          email: email,
          dateTime: selectedSlot.dateTime,
          amount: 0, // No payment required
          currency: 'INR',
          googleMeetLink: result.booking.googleMeetLink || 'https://meet.google.com/czy-ihvn-rho',
        };

        // Send confirmation email
        try {
          const emailResponse = await fetch(`${apiUrl}/api/send-booking-email`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              email: email,
              name: name,
              teachersEmail: 'shubhamanichandrashekar@gmail.com',
              bookingDetails: {
                bookingId: result.booking.bookingId,
                dateTime: selectedSlot.dateTime,
                amount: 0,
                currency: currency,
                googleMeetLink: result.booking.googleMeetLink || 'https://meet.google.com/czy-ihvn-rho'
              }
            }),
          });

          if (!emailResponse.ok) {
            console.error('Failed to send confirmation email:', await emailResponse.text());
            message.warning('Booking confirmed but confirmation email could not be sent. Please check your dashboard for booking details.');
          } else {
            message.success('Booking confirmed! Check your email for details.');
          }
        } catch (emailError) {
          console.error('Error sending confirmation email:', emailError);
          message.warning('Booking confirmed but confirmation email could not be sent. Please check your dashboard for booking details.');
        }

        // Send calendar invite
        try {
          const calendarResponse = await fetch(`${apiUrl}/api/calendar-events`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              studentName: name,
              studentEmail: email,
              dateTime: selectedSlot.dateTime,
              bookingId: result.booking.bookingId,
              googleMeetLink: result.booking.googleMeetLink || 'https://meet.google.com/czy-ihvn-rho',
              teacherEmail: 'shubhamanichandrashekar@gmail.com'
            })
          });

          if (!calendarResponse.ok) {
            throw new Error('Failed to create calendar event');
          }
        } catch (calendarError) {
          console.error('Calendar integration failed:', calendarError);
          message.warning('Booking confirmed but calendar invitation could not be sent.');
        }

        setConfirmationDetails(confirmationDetails);
        setIsBookingConfirmed(true);
        setShowModal(false);
        await fetchWeeklySlots();
        fetchStudentProfile(); // Refresh credits
        return;
      }

      // If no credits, proceed with regular payment flow
      let discountedAmountFinal = discountedAmount || paymentAmount;

      // Validate the coupon before proceeding
      if (couponCode) {
        const couponResponse = await fetch(`${apiUrl}/api/coupons/apply`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code: couponCode })
        });

        if (!couponResponse.ok) {
          const errorData = await couponResponse.json();
          setErrors({ coupon: errorData.message || 'Invalid coupon' });
          return; // Stop execution if coupon validation fails
        }

        const couponResult = await couponResponse.json();

        // Calculate the discounted amount using the discount value
        if (couponResult && couponResult.coupon && couponResult.coupon.discount !== undefined) {
          const discountValue = couponResult.coupon.discount;
          discountedAmountFinal = paymentAmount - (paymentAmount * (discountValue / 100));
        } else {
          setErrors({ coupon: 'Failed to apply coupon' });
          return;
        }
      }

      // Calculate the total amount (class cost + fees)
      const totalAmount = calculateTotalAmount(discountedAmountFinal);

      // Check or create the student's profile
      const profileCreated = await checkOrCreateStudentProfile();
      if (!profileCreated) {
        throw new Error('Unable to create/verify student profile');
      }

      // Create the booking and send the total amount to the backend
      const response = await fetch(`${apiUrl}/create-booking`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          slotId: selectedSlot._id,
          studentName: name,
          studentEmail: email,
          studentPhone: phone,
          paymentAmount: parseFloat(totalAmount), // Send the total amount including fees
          currency,
          couponApplied: couponCode || null, // Pass the coupon code if applied
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();

      // Prepare Razorpay payment options
      const options = {
        amount: Math.round(totalAmount * 100), // Convert to smallest currency unit
        currency: data.currency,
        name: 'Class Booking',
        description: 'Payment for class booking',
        order_id: data.orderId, // Razorpay order ID
        handler: async (response) => {
          try {
            const successResponse = await fetch(`${apiUrl}/payment-success`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                order_id: response.razorpay_order_id,
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              }),
            });

            if (!successResponse.ok) {
              const errorText = await successResponse.text();
              throw new Error(errorText);
            }

            const successData = await successResponse.json();

            // Handle booking confirmation
            const confirmationDetails = {
              bookingId: successData.booking.bookingId,
              name: name,
              email: email,
              dateTime: selectedSlot.dateTime,
              amount: totalAmount,
              currency: currency,
              googleMeetLink: successData.booking.googleMeetLink || 'https://meet.google.com/czy-ihvn-rho',
            };

            // After payment is verified, send confirmation email
            try {
              const emailResponse = await fetch(`${apiUrl}/api/send-booking-email`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  email: email, // Use actual user email
                  name: name,
                  teachersEmail: 'shubhamanichandrashekar@gmail.com',
                  bookingDetails: {
                    bookingId: successData.booking.bookingId,
                    dateTime: selectedSlot.dateTime,
                    amount: totalAmount,
                    currency: currency,
                    googleMeetLink: successData.booking.googleMeetLink || 'https://meet.google.com/czy-ihvn-rho'
                  }
                }),
              });

              if (!emailResponse.ok) {
                // Log error but don't block the booking confirmation
                console.error('Failed to send confirmation email:', await emailResponse.text());
                message.warning('Booking confirmed but confirmation email could not be sent. Please check your dashboard for booking details.');
              } else {
                message.success('Booking confirmed! Check your email for details.');
              }
            } catch (emailError) {
              // Log error but don't block the booking confirmation
              console.error('Error sending confirmation email:', emailError);
              message.warning('Booking confirmed but confirmation email could not be sent. Please check your dashboard for booking details.');
            }

            //send calender invite
            // Inside your payment success handler
            try {
              // After payment verification
              const calendarResponse = await fetch(`${apiUrl}/api/calendar-events`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  studentName: name,
                  studentEmail: email,
                  dateTime: selectedSlot.dateTime,
                  bookingId: successData.booking.bookingId,
                  googleMeetLink: successData.booking.googleMeetLink || 'https://meet.google.com/czy-ihvn-rho',
                  teacherEmail: 'shubhamanichandrashekar@gmail.com'
                })
              });

              if (!calendarResponse.ok) {
                throw new Error('Failed to create calendar event');
              }

              const calendarData = await calendarResponse.json();
              // You can use calendarData.eventLink if needed
            } catch (calendarError) {
              console.error('Calendar integration failed:', calendarError);
              message.warning('Booking confirmed but calendar invitation could not be sent.');
            }

            // Handle booking confirmation
            setConfirmationDetails(confirmationDetails);
            setIsBookingConfirmed(true);
            setShowModal(false);
            await fetchWeeklySlots();
          } catch (err) {
            setErrors({ form: `Failed to update slot status: ${err.message}` });
          }
        },
        prefill: {
          name: name,
          email: email,
          contact: phone,
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.on('payment.failed', function (response) {
        setErrors({ form: `Payment failed: ${response.error.description}` });
      });
      rzp.open();
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      setErrors({ form: `Failed to process booking: ${err.message}` });
      message.error('Failed to process booking');
    }
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  useEffect(() => {
    if (activeView === 'activeClasses' || activeView === 'history') {
      const fetchBookings = async () => {
        try {
          const response = await fetch(`${apiUrl}/api/student/${user.email}/bookings`);
          const data = await response.json();
          const currentDate = new Date();

          // Split bookings into active and history
          const active = data.filter(booking => new Date(booking.slotId?.dateTime) > currentDate);
          const history = data.filter(booking => new Date(booking.slotId?.dateTime) <= currentDate);

          setActiveBookings(active);
          setHistoryBookings(history);
        } catch (err) {
        }
      };
      fetchBookings();
    }
  }, [activeView, user.email]);

  const handleCancelBooking = (id) => {
    Modal.confirm({
      title: 'Cancel Booking',
      content: 'Are you sure you want to cancel this booking?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => onCancel(id)
    });
  };

  const onCancel = async (id) => {
    setCancellingId(id);
    try {
      // First update the UI optimistically
      setActiveBookings(prevBookings =>
        prevBookings.map(booking =>
          booking._id === id
            ? { ...booking, bookingStatus: 'cancelled' }  // Only update booking status
            : booking
        )
      );

      // Then make the API call
      const response = await fetch(`${apiUrl}/api/cancel-booking/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to cancel booking');
      }

      // Refresh the bookings to get the latest state
      const updatedResponse = await fetch(`${apiUrl}/api/student/${user.email}/bookings`);
      const data = await response.json();
      const currentDate = new Date();

      // Update active bookings while preserving payment status
      setActiveBookings(data.filter(booking =>
        new Date(booking.slotId?.dateTime) > currentDate
      ));
    } catch (err) {
      // Revert the optimistic update if the API call failed
      const response = await fetch(`${apiUrl}/api/student/${user.email}/bookings`);
      const data = await response.json();
      const currentDate = new Date();
      setActiveBookings(data.filter(booking =>
        new Date(booking.slotId?.dateTime) > currentDate
      ));
    } finally {
      setCancellingId(null);
    }
  };

  const activeClassesColumns = [
    {
      title: 'Class Date',
      dataIndex: 'classDate',
      key: 'classDate',
    },
    {
      title: 'Class Time',
      dataIndex: 'classTime',
      key: 'classTime',
    },
    {
      title: 'Google Meet Link',
      dataIndex: 'googleMeetLink',
      key: 'googleMeetLink',
      render: (link) => (
        <a href={link} target="_blank" rel="noopener noreferrer">
          Join
        </a>
      ),
    },
    {
      title: 'Class Cost',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (status) => status === 'paid' ? 'Paid' : 'Pending'
    },
    {
      title: 'Booking Status',
      dataIndex: 'bookingStatus',
      key: 'bookingStatus',
      render: (status) => status === 'cancelled' ? 'Cancelled' : 'Active'
    },
    {
      title: 'Booking Date',
      dataIndex: 'bookingDate',
      key: 'bookingDate',
    },
  ];

  const historyClassesColumns = [
    {
      title: 'Class Date',
      dataIndex: 'classDate',
      key: 'classDate',
    },
    {
      title: 'Class Time',
      dataIndex: 'classTime',
      key: 'classTime',
    },
    {
      title: 'Class Cost',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
    },
  ];

  useEffect(() => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (localTimeZone.includes('Asia/')) {
      setCurrency('INR');
      setPaymentAmount(999);
    } else {
      setCurrency('USD');
      setPaymentAmount(24.99);
    }
  }, []); // Empty dependency array means this runs once when component mounts

  const handlePaymentSuccess = async (response) => {
    try {
      const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;

      // Verify payment with the server
      const verifyResponse = await fetch(`${apiUrl}/payment-success`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          order_id: razorpay_order_id,
          payment_id: razorpay_payment_id,
          signature: razorpay_signature
        })
      });

      const data = await verifyResponse.json();

      if (verifyResponse.ok && data.success) {
        const confirmationDetails = {
          bookingId: data.booking.bookingId,
          name: name,
          email: email,
          dateTime: data.booking.dateTime,
          amount: data.booking.amount,
          currency: data.booking.currency,
          googleMeetLink: data.booking.googleMeetLink || 'https://meet.google.com/czy-ihvn-rho'
        };

        // Call the welcome email endpoint 
        const emailResponse = await fetch(`${apiUrl}/api/send-booking-email`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: 'shubhamanichandrashekar@gmail.com', // Use the user's email
            name: name,   // Use the user's name
          }),
        });

        if (!emailResponse.ok) {
          throw new Error('Failed to send welcome email');
        }

        // Navigate to confirmation with state
        navigate('/confirmation', {
          state: { confirmationDetails }
        });
      } else {
        throw new Error('Payment verification failed');
      }
    } catch (error) {
      setErrors({ payment: error.message });
    }
  };

  useEffect(() => {
    if (!location.hash) {
      navigate('/booking#dashboard');
    } else if (location.hash === '#activeclasses') {
      setActiveView('activeClasses');
    } else if (location.hash === '#dashboard') {
      setActiveView('dashboard');
    } else if (location.hash === '#history') {
      setActiveView('history');
    } else if (location.hash === '#account') {
      setActiveView('account');
    }
  }, [location.hash, navigate]);

  // Function to handle filter change
  const handleFilterChange = (e) => {
    setPaymentStatusFilter(e.target.value);
  };

  // Filtered bookings based on selected payment status
  const filteredActiveBookings = activeBookings.filter(booking => {
    return paymentStatusFilter === 'all' || booking.paymentStatus === paymentStatusFilter;
  });

  const handleBulkPurchase = async (packageSize, price, expiryDays) => {
    // Get the same currency logic as single classes
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const isINR = localTimeZone.includes('Asia/');
    
    // Calculate prices based on currency
    const packagePrices = {
      4: isINR ? 3999 : 99.99,
      8: isINR ? 7999 : 199.99
    };

    const actualPrice = packagePrices[packageSize];
    const currency = isINR ? 'INR' : 'USD';
    
    setBulkPackageDetails({
      packageSize,
      price: actualPrice,
      expiryDays,
      currency
    });
    setIsBulkBooking(true);
    setShowModal(true);
  };

  // Add new handleBulkSubmit function that will be called from the modal
  const handleBulkSubmit = async () => {
    try {
      console.log('Creating Razorpay order...'); 

      // Calculate fees
      const baseAmount = bulkPackageDetails.price;
      const serviceFee = Math.round(baseAmount * 0.10); // 10% service fee
      const razorpayFee = Math.round(baseAmount * 0.024); // 2.4% Razorpay fee
      const totalAmount = baseAmount + serviceFee + razorpayFee;

      const response = await fetch(`${apiUrl}/api/bulk-purchase`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          studentEmail: email,
          packageSize: bulkPackageDetails.packageSize,
          paymentAmount: baseAmount, // Send base amount, let server calculate fees
          currency: bulkPackageDetails.currency,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create Razorpay order');
      }

      const data = await response.json();
      console.log('Razorpay order created:', data);

      // Use total amount from server response
      const options = {
        key: 'rzp_test_HZBcwFmDQvhOrY',
        amount: data.amount, // This will be the total with fees
        currency: data.currency,
        order_id: data.orderId,
        handler: async function (response) {
          console.log('Payment successful:', response);

          try {
            const paymentResponse = await fetch(`${apiUrl}/api/payment-success-bulk`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                order_id: data.orderId,
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              }),
            });

            if (!paymentResponse.ok) {
              const errorData = await paymentResponse.json();
              console.error('Payment verification failed:', errorData);
              message.error(errorData.error || 'Payment verification failed');
              return;
            }

            const result = await paymentResponse.json();
            console.log('Bulk payment response:', result);
            
            if (result.success) {
              message.success('Classes purchased successfully!');
              fetchStudentProfile(); // Refresh credits after payment
              setShowModal(false); // Close the modal after successful payment
              setIsBulkBooking(false);
              setBulkPackageDetails(null);
            } else {
              throw new Error(result.error || 'Payment verification failed');
            }
          } catch (error) {
            console.error('Error verifying bulk payment:', error);
            message.error('Failed to verify payment. Please contact support.');
          }
        },
        prefill: {
          name: name,
          email: email,
          contact: phone,
        },
        theme: {
          color: '#3399cc',
        },
      };

      console.log('Opening Razorpay modal...');
      const razorpayInstance = new window.Razorpay(options);
      razorpayInstance.open();
    } catch (error) {
      console.error('Bulk purchase failed:', error);
      message.error('Failed to process bulk purchase');
    }
  };

  const fetchStudentProfile = async () => {
    try {
      console.log('Fetching profile with email:', email);
      const response = await fetch(`${apiUrl}/api/student-profile?email=${email}`);
      const data = await response.json();
      setCredits(data.classCredits);
      
      // Format date consistently
      const expiryDate = new Date(data.classCreditsExpiry);
      const formattedDate = expiryDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });
      setCreditsExpiry(formattedDate);

      // Check if credits are expiring soon
      if (data.classCredits > 0) {
        const daysUntilExpiry = (expiryDate - new Date()) / (1000 * 60 * 60 * 24);
        if (daysUntilExpiry <= 7 && daysUntilExpiry > 0) {
          alert(`Your class credits are expiring soon! You have ${Math.ceil(daysUntilExpiry)} days left.`);
        }
      }
    } catch (error) {
      console.error('Failed to fetch student profile:', error);
    }
  };

  useEffect(() => {
    fetchStudentProfile();
  }, [email]);

  useEffect(() => {
    const checkCredits = async () => {
      const response = await fetch(`${apiUrl}/api/student-profile?email=${email}`);
      const data = await response.json();
      setHasValidCredits(
        data.classCredits > 0 && new Date(data.classCreditsExpiry) > new Date()
      );
    };
    checkCredits();
  }, [email]);

  // Update calculateBulkTotal to use the service fee function
  const calculateBulkTotal = (packagePrice, currency) => {
    if (!packagePrice) return 0;
    const serviceFee = parseFloat(calculateServiceFee(packagePrice));
    const razorpayFee = parseFloat((packagePrice * 0.024).toFixed(2));
    return (parseFloat(packagePrice) + serviceFee + razorpayFee).toFixed(2);
  };

  // // For the expiry warning alert
  // useEffect(() => {
  //   if (credits > 0 && creditsExpiry) {  // Only proceed if there are credits
  //     const expiryDate = new Date(creditsExpiry);
  //     const daysUntilExpiry = (expiryDate - new Date()) / (1000 * 60 * 60 * 24);
  //     if (daysUntilExpiry <= 7 && daysUntilExpiry > 0) {  // Check if not already expired
  //       alert(`Your class credits are expiring soon! You have ${Math.ceil(daysUntilExpiry)} days left.`);
  //     }
  //   }
  // }, [credits, creditsExpiry]);  // Dependencies array

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          color: 'white',
          fontSize: '1.5em',
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <span>Welcome to Mani's DanceHall, {userName}</span>
      </Header>
      <Layout>
        <Sider width={200}>
          <Menu
            mode="inline"
            defaultSelectedKeys={[
              location.hash === '#activeclasses' ? '2' :
                location.hash === '#history' ? '3' : '1'
            ]}
            style={{ height: '100%' }}
            items={menuItems}
          />
        </Sider>
        <Content style={{ padding: '0 24px', minHeight: 280 }}>
          {isBookingConfirmed && confirmationDetails ? (
            <Confirmation bookingDetails={confirmationDetails} />
          ) : (
            <>
              {activeView === 'dashboard' && (
                <div className="dashboard-content">
                  <div className="calendar-section" style={{ marginTop: '24px' }}>
                    <Title level={2} style={{ marginBottom: '16px' }}>Select a date</Title>
                    <Calendar onChange={handleDateSelect} value={date} minDate={new Date()} locale="en-US" />
                  </div>
                  <div className="available-classes">
                    <h2>Available Classes</h2>
                    <div className="class-list-body">
                      {/* Display the slots for each day */}
                      <Row gutter={[16, 16]}>
                        {displayedDays.map((day) => (
                          <Col span={4} key={day}>
                            <h3 style={{ textAlign: 'center' }}>{dayjs(day).format('ddd, MMM DD')}</h3>
                            {filteredSlots[day] && filteredSlots[day].length > 0 ? (
                              <ul style={{ padding: 0, listStyleType: 'none' }}>
                                {filteredSlots[day].map((slot) => (
                                  <li key={slot._id} style={{ marginBottom: '8px' }}>
                                    <Button
                                      className={selectedSlot && selectedSlot._id === slot._id ? 'selected' : ''}
                                      style={{ width: '100%' }}
                                      onClick={() => handleSlotSelect(slot)}
                                    >
                                      {dayjs(slot.dateTime).format('h:mm A')}
                                    </Button>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <p style={{ color: 'gray', textAlign: 'center' }}>No slots</p>
                            )}
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </div>
              )}
              {activeView === 'activeClasses' && (
                <div style={{ padding: '24px' }}>
                  <h2>Your Active Bookings</h2>
                  <div>
                    <label>Filter by Payment Status: </label>
                    <select value={paymentStatusFilter} onChange={handleFilterChange}>
                      <option value="all">All</option>
                      <option value="paid">Paid</option>
                      <option value="pending">Pending</option>
                    </select>
                  </div>
                  <Table
                    columns={activeClassesColumns}
                    dataSource={filteredActiveBookings.map(booking => ({
                      key: booking._id,
                      id: booking._id,
                      classDate: formatDate(new Date(booking.slotId?.dateTime)),
                      classTime: new Date(booking.slotId?.dateTime).toLocaleTimeString('en-US', { timeZone: localTimeZone }),
                      googleMeetLink: booking.googleMeetLink,
                      paymentAmount: booking.paymentAmount,
                      paymentStatus: booking.paymentStatus,
                      bookingStatus: booking.bookingStatus,
                      bookingDate: formatDate(new Date(booking.createdAt))
                    }))}
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              )}
              {activeView === 'history' && (
                <div style={{ padding: '24px' }}>
                  <h2>History Bookings</h2>
                  <Table
                    columns={historyClassesColumns}
                    dataSource={historyBookings.map(booking => ({
                      key: booking._id,
                      id: booking._id,
                      classDate: formatDate(new Date(booking.slotId?.dateTime)),
                      classTime: new Date(booking.slotId?.dateTime).toLocaleTimeString('en-US', { timeZone: localTimeZone }),
                      paymentAmount: booking.paymentAmount,
                      paymentStatus: booking.paymentStatus,
                    }))}
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              )}
              {activeView === 'account' && (
                <div className="account-section">
                  <h1>Account</h1>
                  <div className="credits-info" style={{ 
                    backgroundColor: '#f5f5f5', 
                    padding: '24px', 
                    borderRadius: '8px' 
                  }}>
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
                      {credits} Classes Remaining
                    </p>
                    {credits > 0 && creditsExpiry && (  // Only show expiry if credits exist
                      <p>Expires on: {creditsExpiry}</p>
                    )}
                  </div>
                  
                  {/* Bulk package buttons */}
                  <div style={{ 
                    display: 'flex', 
                    gap: '16px', 
                    marginTop: '24px' 
                  }}>
                    <Button 
                      type="primary" 
                      size="large"
                      onClick={() => handleBulkPurchase(4, 99, 30)}
                    >
                      Buy 4 Classes
                    </Button>
                    <Button 
                      type="primary" 
                      size="large"
                      onClick={() => handleBulkPurchase(8, 189, 60)}
                    >
                      Buy 8 Classes
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </Content>
      </Layout>
      <Footer style={{ textAlign: 'center' }}>My Dance Hall ©{new Date().getFullYear()}</Footer>

      {/* Payment Form Modal */}
      <Modal
        open={showModal}
        title={isBulkBooking ? "Complete Bulk Package Purchase" : "Complete Your Booking"}
        onCancel={() => {
          setShowModal(false);
          setCouponCode('');
          setDiscountedAmount(null);
          setCouponError('');
          setIsBulkBooking(false);
          setBulkPackageDetails(null);
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setShowModal(false);
              setCouponCode('');
              setDiscountedAmount(null);
              setCouponError('');
              setIsBulkBooking(false);
              setBulkPackageDetails(null);
            }}
            style={{ height: '40px', fontSize: '16px' }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={isBulkBooking ? handleBulkSubmit : handleSubmit}
            style={{ height: '40px', fontSize: '16px' }}
          >
            {isBulkBooking ? 'Purchase Package' : hasValidCredits ? 'Book with Credits' : 'Dance Away!'}
          </Button>
        ]}
        width={600}
      >
        <Form
          name="paymentForm"
          layout="vertical"
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            padding: '0 12px'
          }}
        >
          {/* User Details - Improved Layout */}
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '16px',
            backgroundColor: '#f5f5f5',
            padding: '16px',
            borderRadius: '8px'
          }}>
            {/* Name Section */}
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px'
            }}>
              <label style={{
                fontWeight: 'bold',
                color: '#333',
                fontSize: '14px'
              }}>
                Name
              </label>
              <div style={{
                backgroundColor: 'white',
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #d9d9d9'
              }}>
                {name}
              </div>
            </div>

            {/* Email Section */}
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px'
            }}>
              <label style={{
                fontWeight: 'bold',
                color: '#333',
                fontSize: '14px'
              }}>
                Email
              </label>
              <div style={{
                backgroundColor: 'white',
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #d9d9d9'
              }}>
                {email}
              </div>
            </div>

            {/* Phone Section */}
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px'
            }}>
              <label style={{
                fontWeight: 'bold',
                color: '#333',
                fontSize: '14px'
              }}>
                Phone Number
              </label>
              <Input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter phone number"
                style={{
                  height: '40px',
                  borderRadius: '4px'
                }}
              />
            </div>

            {/* Coupon Section */}
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px'
            }}>
              <label style={{
                fontWeight: 'bold',
                color: '#333',
                fontSize: '14px'
              }}>
                Coupon Code (Optional)
              </label>
              <Input.Group compact style={{ display: 'flex' }}>
                <Input
                  style={{
                    height: '40px',
                    flex: 1,
                    borderTopLeftRadius: '4px',
                    borderBottomLeftRadius: '4px'
                  }}
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
                  placeholder="Enter coupon"
                />
                <Button
                  type="primary"
                  onClick={handleCouponApply}
                  style={{
                    height: '40px',
                    borderTopRightRadius: '4px',
                    borderBottomRightRadius: '4px'
                  }}
                >Apply
                </Button>
              </Input.Group>
            </div>
          </div>

          {/* Coupon Feedback */}
          {(couponError || discountedAmount) && (
            <div style={{
              padding: '8px',
              borderRadius: '4px',
              backgroundColor: couponError ? '#fff2f0' : '#f6ffed',
              color: couponError ? '#ff4d4f' : '#52c41a',
              fontSize: '12px',
              textAlign: 'center'
            }}>
              {couponError || 'Discount applied!'}
            </div>
          )}

          {/* Price Breakdown */}
          <div style={{
            backgroundColor: '#f5f5f5',
            padding: '16px',
            borderRadius: '8px'
          }}>
            {isBulkBooking ? (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <span>{bulkPackageDetails?.packageSize} Classes Package</span>
                  <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    {discountedAmount ? (
                      <>
                        <span style={{ textDecoration: 'line-through', color: '#999' }}>
                          {bulkPackageDetails?.currency} {bulkPackageDetails?.price}
                        </span>
                        <span>{bulkPackageDetails?.currency} {discountedAmount}</span>
                      </>
                    ) : (
                      <span>{bulkPackageDetails?.currency} {bulkPackageDetails?.price}</span>
                    )}
                  </span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <span>Dancehall Service Fee (10%)</span>
                  <span>{bulkPackageDetails?.currency} {calculateServiceFee(discountedAmount || bulkPackageDetails?.price)}</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <span>Razorpay Service Fee</span>
                  <span>{bulkPackageDetails?.currency} {((discountedAmount || bulkPackageDetails?.price) * 0.024).toFixed(2)}</span>
                </div>
              </>
            ) : hasValidCredits ? (
              <>
                <div style={{ marginBottom: '16px' }}>
                  <p>Using 1 credit from your account</p>
                  <p>Remaining credits after booking: {credits - 1}</p>
                  <p>Credits expire on: {creditsExpiry}</p>
                </div>
              </>
            ) : (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <span>Class Cost</span>
                  <span>{currency} {discountedAmount || paymentAmount}</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <span>Dancehall Service Fee</span>
                  <span>{currency} {(currency === 'USD' ? 2.5 : 99).toFixed(2)}</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <span>Razorpay Service Fee</span>
                  <span>{currency} {((discountedAmount || paymentAmount) * 0.024).toFixed(2)}</span>
                </div>
              </>
            )}
          </div>

          {/* Total Amount - only show for paid bookings */}
          {(!hasValidCredits || isBulkBooking) && (
            <div style={{
              backgroundColor: '#f5f5f5',
              padding: '16px',
              borderRadius: '8px',
              fontSize: '18px',
              fontWeight: 'bold',
              marginTop: '16px'
            }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Total</span>
                <span>
                  {isBulkBooking 
                    ? `${bulkPackageDetails?.currency} ${calculateBulkTotal(discountedAmount || bulkPackageDetails?.price, bulkPackageDetails?.currency)}`
                    : `${currency} ${calculateTotalAmount(discountedAmount || paymentAmount)}`
                  }
                </span>
              </div>
            </div>
          )}
        </Form>
      </Modal>
    </Layout>
  );
};

export default Booking;
