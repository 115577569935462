import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { RoleContext } from '../context/RoleContext';
import { Layout, Typography, Button, Row, Col, Card, Divider, Space } from 'antd';
import WaitlistPopup from './WaitlistPopup'; // Import the WaitlistPopup component
import './waitlist.css'; // Import the CSS for the popup
import { 
  CalendarOutlined, 
  DollarOutlined, 
  LineChartOutlined, 
  ScheduleOutlined, 
  BookOutlined, 
  TrophyOutlined, 
  ReadOutlined,
  CheckOutlined,
  InstagramOutlined, 
  YoutubeOutlined 
} from '@ant-design/icons';
import { Collapse } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { message } from 'antd';
import VerificationPendingModal from './VerificationPendingModal';;
const { Panel } = Collapse;
const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;

const Public = () => {
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const { setRole } = useContext(RoleContext);
  const [showVerificationMessage, setShowVerificationMessage] = useState(false);
  const [isWaitlistOpen, setWaitlistOpen] = useState(false);

  const handleLogin = async (role) => {
    try {
      
      await loginWithRedirect({
        authorizationParams: { 
          redirect_uri: window.location.origin,
          screen_hint: 'signup', // Ensure the signup screen is shown
        },
        // appState: { returnTo: role === 'student' ? '/booking' : '/teacher', role },
        appState: { role },
      });
      
    } catch (error) {
      
    }
  };
  const fetchRole = async (email) => {
    try {
      // Make sure we have a complete URL with protocol
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
      const response = await fetch(`${apiUrl}/api/login`, {
      // const response = await fetch('http://localhost:3001/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ email })
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      
      
      if (!data.isAllowed) {
        // Redirect to the verification pending page
        navigate('/verification-pending');
        return null;
      }
      
      
      return data.role;
    } catch (error) {
      
      message.error('An error occurred during login');
      return null;
    }
  };

  // Add FAQ data
const faqData = [
    {
      question: "What is DanceHall?",
      answer: "DanceHall is a web application designed to support teachers and students in the world of Indian classical dance. It offers tools for class management, payments, student tracking, and more."
    },
    {
      question: "How can I list my school?",
      answer: "To list your school, click on 'List my school' at the top of the page, sign up as a teacher, and follow the prompts to create and manage your school's profile."
    },
    {
      question: "How does payments work?",
      answer: "Payments are processed securely through our platform. Teachers can set fees, and students can pay directly through the website using secure payment gateways. All payments are tracked and visible in your dashboard."
    },
    {
      question: "How does cancellation work?",
      answer: "Our platform allows both teachers and students to cancel bookings within a specified timeframe. Refunds are processed automatically based on our cancellation policy."
    },
    {
      question: "How does payouts work?",
      answer: "Payouts to teachers are processed periodically. You can view your earnings and request withdrawals from your dashboard. Our platform ensures secure and timely payouts to your bank account."
    }
  ];

  useEffect(() => {
    console.log('useEffect called with isAuthenticated:', isAuthenticated);
    console.log('User object:', user);
    if(isAuthenticated && user){
      fetchRole(user.email).then(userRole=>{
        if(userRole){
          setRole(userRole);
          if(userRole==="student"){
            navigate('/booking');
          }else if(userRole==='teacher'){
            navigate('/teacher');
          }
        }
      });
    }
  }, [isAuthenticated, user, navigate, setRole]);

  // Define the function to open the waitlist popup
  const handleWaitlistOpen = () => {
    setWaitlistOpen(true);
  };

  // Define the function to close the waitlist popup
  const handleWaitlistClose = () => {
    setWaitlistOpen(false);
  };

  // Update only the header section to include Auth0 functionality
  return (
    <Layout>
      <Header style={{ 
        background: '#fff', 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 16px',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        '@media (min-width: 768px)': {
          padding: '0 50px'
        }
      }}>
        <div className="logo" style={{ fontSize: '20px', fontWeight: 'bold' }}>DanceHall</div>
        <div style={{ display: 'flex', gap: '12px', alignItems: 'center', '@media (min-width: 768px)': { gap: '20px' } }}>
          <Button type="link"style={{fontSize: '110%', color: 'black'}} onClick={handleWaitlistOpen}>List my school</Button>
          
          {isAuthenticated ? (
            <Button
              type="primary"
              onClick={() => {
                const returnTo = `${window.location.origin}`;
                console.log('Logging out with returnTo:', returnTo);
                logout({ logoutParams: { returnTo } });
              }}
            >
              Logout
            </Button>
          ) : (
            <>
              {/* <Button type="primary" onClick={() => handleLogin('teacher')}>Teacher</Button> */}
              <Button type="link" style={{fontSize: '110%', color: 'black'}} onClick={() => handleLogin('student')}>Log in</Button>
              <Button type="primary" onClick={handleWaitlistOpen}>Join My DanceHall</Button>
            </>
          )}
        </div>
      </Header>

      <Content>
        {/* Hero Section */}
        <Row justify="center" align="middle" style={{ 
          padding: '200px 16px',
          background: 'linear-gradient(180deg, #E1F0FF 0%, #F5F9FF 100%)', // Light blue to very light blue
          '@media (min-width: 768px)': {
            padding: '60px 50px'
          }
        }}>
          <Col xs={24} sm={24} md={16} style={{ textAlign: 'center' }}>
            <Title level={1} style={{ 
              fontSize: '50px',
              '@media (min-width: 768px)': {
                fontSize: '48px'
              }
            }}>
              Your Digital School for Indian Classical Dance
            </Title>
            <Text style={{ 
              fontSize: '20px',
              display: 'block',
              marginBottom: '24px',
              '@media (min-width: 768px)': {
                fontSize: '18px'
              }
            }}>
              A one-stop solution for teachers to build their online school – manage student progress, 
              payments, notifications, feedback, and learning resources all in one place.
            </Text>
            <Button type="primary" size="large" onClick={handleWaitlistOpen}>Join My DanceHall</Button>
          </Col>
        </Row>

        {/* Waitlist Popup */}
        {isWaitlistOpen && (
          <WaitlistPopup isOpen={isWaitlistOpen} onClose={handleWaitlistClose} />
        )}

        {/* Features Section */}
        <Row justify="center" style={{ 
          padding: '150px 16px', 
          background: 'linear-gradient(180deg, #F5F9FF 0%, #FFFFFF 100%)', // Very light blue to white
          '@media (min-width: 768px)': {
            padding: '40px 50px'
          }
        }}>
          <Col xs={24} sm={24} md={20}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Card 
                  title={<Title level={3}>For Teachers</Title>} 
                  bordered={false}
                  style={{ height: '100%' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                      <CalendarOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                      <div>
                        <Text strong>Manage Classes</Text>
                        <Text style={{ display: 'block' }}>Schedule and organize your classes with ease.</Text>
                      </div>
                    </div>
                    
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                      <DollarOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                      <div>
                        <Text strong>Manage Payments</Text>
                        <Text style={{ display: 'block' }}>Simplify payment tracking and collection.</Text>
                      </div>
                    </div>
                    
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                      <LineChartOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                      <div>
                        <Text strong>Track Student Progress</Text>
                        <Text style={{ display: 'block' }}>Monitor each student's growth and milestones.</Text>
                      </div>
                    </div>
                    
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                      <ScheduleOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                      <div>
                        <Text strong>Get Appointments</Text>
                        <Text style={{ display: 'block' }}>Schedule one-on-one appointments effortlessly.</Text>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
              
              <Col xs={24} md={12}>
                <Card 
                  title={<Title level={3}>For Students</Title>} 
                  bordered={false}
                  style={{ height: '100%' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                      <BookOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                      <div>
                        <Text strong>Book Classes</Text>
                        <Text style={{ display: 'block' }}>Reserve your spot in classes with just a few clicks.</Text>
                      </div>
                    </div>
                    
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                      <TrophyOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                      <div>
                        <Text strong>Track Your Progress</Text>
                        <Text style={{ display: 'block' }}>Stay motivated by following your learning journey.</Text>
                      </div>
                    </div>
                    
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                      <ReadOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                      <div>
                        <Text strong>Access Teacher Materials</Text>
                        <Text style={{ display: 'block' }}>Enjoy exclusive access to learning materials shared by your teacher.</Text>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Pricing Section */}
        <Row
          justify="center"
          style={{
            padding: '150px 16px',
            // background: 'linear-gradient(135deg, #f0f0f0, #e0e0e0)', // Adjust to match hero section
          }}
        >
          <Col xs={24} sm={24} md={20}>
            <Title
              level={2}
              style={{
                textAlign: 'center',
                margin: '30px 0',
                fontSize: '32px',
              }}
            >
              Find the Perfect Plan for Your Teaching Goals
            </Title>
            <Text style={{ textAlign: 'center', display: 'block', marginBottom: '40px' }}>
              Choose a plan that suits your teaching style, and scale with MyDanceHall's support.
            </Text>
            <Row gutter={[16, 16]} justify="center">
              {/* Starter Plan */}
              <Col xs={24} sm={24} md={8}>
                <Card
                  title={<Title level={3} style={{ color: '#fff', backgroundColor: '#1677ff', padding: '10px', borderRadius: '8px 8px 0 0' }}>Starter Plan</Title>}
                  bordered={false}
                  style={{
                    height: '100%',
                    textAlign: 'center',
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  }}
                >
                  <Title level={2} style={{ margin: '24px 0' }}>
                    $0<Text style={{ fontSize: '16px' }}>/month</Text>
                  </Title>
                  {/* <p>Perfect for teachers starting their journey with up to 8 students.</p> */}
                  <ul
                    style={{
                      listStyle: 'none',
                      padding: 0,
                      textAlign: 'left',
                      margin: '0 auto',
                      maxWidth: '280px',
                    }}
                  >
                    <li style={{ margin: '16px 0' }}>
                      <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
                      No cost to start a class.
                    </li>
                    <li style={{ margin: '16px 0' }}>
                      <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
                      Manage up to 8 students easily.
                    </li>
                    <li style={{ margin: '16px 0' }}>
                      <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
                      10% platform fee on every booking.
                    </li>
                    <li style={{ margin: '16px 0' }}>
                      <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
                      Simple, predictable pricing.
                    </li>
                  </ul>
                </Card>
              </Col>

              {/* Growth Plan */}
              <Col xs={24} sm={24} md={8}>
                <Card
                  title={<Title level={3} style={{ color: '#fff', backgroundColor: '#d9d9d9', padding: '10px', borderRadius: '8px 8px 0 0' }}>Growth Plan – Coming Soon!</Title>}
                  bordered={false}
                  style={{
                    height: '100%',
                    textAlign: 'center',
                    borderRadius: '8px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  }}
                >
                  <Title level={2} style={{ margin: '24px 0' }}>
                    From $30
                    <Text style={{ fontSize: '16px' }}>/month</Text>
                  </Title>
                  {/* <p>Unlock unlimited possibilities with advanced tools for scaling your dance business.</p> */}
                  <ul
                    style={{
                      listStyle: 'none',
                      padding: 0,
                      textAlign: 'left',
                      margin: '0 auto',
                      maxWidth: '280px',
                    }}
                  >
                    <li style={{ margin: '16px 0' }}>
                      <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
                      Unlimited students.
                    </li>
                    <li style={{ margin: '16px 0' }}>
                      <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
                      Marketing support to grow your school.
                    </li>
                    <li style={{ margin: '16px 0' }}>
                      <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
                      Group classes to maximize your time.
                    </li>
                    <li style={{ margin: '16px 0' }}>
                      <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
                      Sell your materials, tickets, and events.
                    </li>
                    <li style={{ margin: '16px 0' }}>
                      <CheckOutlined style={{ color: '#52c41a', marginRight: '8px' }} />
                      50% off on platform commission.
                    </li>
                  </ul>
                </Card>
              </Col>
            </Row>

            {/* Centered Get Started Button */}
            <div style={{ textAlign: 'center', marginTop: '40px' }}>
              <Button
                type="primary"
                size="large"
                style={{
                  backgroundColor: '#1677ff',
                  borderColor: '#1677ff',
                  fontSize: '18px', // Increase font size
                  padding: '12px 44px', // Increase padding
                  height: '40px', // Adjust height if needed
                }}
                onClick={handleWaitlistOpen}
              >
                Join My DanceHall
              </Button>
            </div>
          </Col>
        </Row>


<Row justify="center" style={{ 
  padding: '40px 16px', 
  background: 'linear-gradient(180deg, #FFFFFF 0%, #F5F9FF 100%)', // Subtle gradient background
  '@media (min-width: 768px)': {
    padding: '60px 50px'
  }
}}>
  <Col xs={24} sm={24} md={20}>
    <Title level={2} style={{ 
      textAlign: 'center', 
      margin: '30px 0',
      fontSize: '24px',
      color: '#1A1A1A',
      '@media (min-width: 768px)': {
        margin: '60px 0 40px',
        fontSize: '32px'
      }
    }}>Frequently Asked Questions</Title>
    
    <Collapse 
      bordered={false}
      expandIconPosition="end"
      style={{ 
        background: 'transparent'
      }}
    >
      {faqData.map((faq, index) => (
        <Panel 
          header={
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <QuestionCircleOutlined style={{ 
                color: '#1890ff',
                fontSize: '20px'
              }} />
              <Text strong style={{ 
                fontSize: '16px',
                color: '#2A2A2A',
                '@media (min-width: 768px)': {
                  fontSize: '18px'
                }
              }}>
                {faq.question}
              </Text>
            </div>
          }
          key={index}
          style={{ 
            marginBottom: '16px',
            background: 'white',
            borderRadius: '8px',
            border: '1px solid #f0f0f0',
            boxShadow: '0 2px 8px rgba(0,0,0,0.04)',
            transition: 'all 0.3s ease',
            '&:hover': {
              boxShadow: '0 4px 12px rgba(0,0,0,0.08)'
            }
          }}
        >
          <div style={{
            padding: '8px 4px',
            color: '#595959',
            fontSize: '14px',
            lineHeight: '1.6',
            '@media (min-width: 768px)': {
              fontSize: '16px',
              padding: '12px 8px'
            }
          }}>
            {faq.answer}
          </div>
        </Panel>
      ))}
    </Collapse>

    {/* Optional: Add a contact support section */}
    <Row justify="center" style={{ marginTop: '40px' }}>
      <Col style={{ textAlign: 'center' }}>
        <Text type="secondary" style={{ 
          display: 'block',
          marginBottom: '16px',
          fontSize: '14px',
          '@media (min-width: 768px)': {
            fontSize: '16px'
          }
        }}>
          Still have questions?
        </Text>
        <Button type="primary" size="large">
          Contact Support
        </Button>
      </Col>
    </Row>
  </Col>
</Row>

<Footer style={{ 
  background: '#001529',
  padding: '40px 16px 24px',
  color: 'rgba(255, 255, 255, 0.85)',
  '@media (min-width: 768px)': {
    padding: '60px 50px 24px'
  }
}}>
  <Row justify="space-between" gutter={[32, 32]} style={{ textAlign: 'left' }}>
    {/* Company Section */}
    <Col xs={24} sm={12} md={6}>
      <Title level={3} style={{ 
        marginBottom: '24px', 
        textAlign: 'left',
        fontSize: '20px',
        color: 'white',
        '@media (min-width: 768px)': {
          fontSize: '24px'
        }
      }}>Company</Title>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: '16px'
      }}>
        {/* <Button type="link" style={{ 
          padding: 0, 
          height: 'auto', 
          color: 'rgba(255, 255, 255, 0.85)',
          textAlign: 'left', 
          justifyContent: 'flex-start',
          fontSize: '14px',
          '@media (min-width: 768px)': {
            fontSize: '16px'
          }
        }}>About Us</Button> */}
        <Button 
          type="link" 
          onClick={() => navigate('/terms')} 
          style={{ 
            padding: 0, 
            height: 'auto', 
            color: 'rgba(255, 255, 255, 0.85)', 
            textAlign: 'left', 
            justifyContent: 'flex-start' 
          }}
        >
          Terms & Conditions
        </Button>
        <Button 
          type="link" 
          onClick={() => navigate('/privacy-policy')} 
          style={{ 
            padding: 0, 
            height: 'auto', 
            color: 'rgba(255, 255, 255, 0.85)', 
            textAlign: 'left', 
            justifyContent: 'flex-start' 
          }}
        >
          Privacy Policy
        </Button>
        <Button 
          type="link" 
          onClick={() => navigate('/refund-policy')} 
          style={{ 
            padding: 0, 
            height: 'auto', 
            color: 'rgba(255, 255, 255, 0.85)', 
            textAlign: 'left', 
            justifyContent: 'flex-start' 
          }}
        >
          Refund & Cancellation Policy
        </Button>
      </div>
    </Col>

    {/* Support Section */}
    <Col xs={24} sm={12} md={6}>
      <Title level={3} style={{ 
        marginBottom: '24px', 
        textAlign: 'left',
        fontSize: '20px',
        color: 'white',
        '@media (min-width: 768px)': {
          fontSize: '24px'
        }
      }}>Support</Title>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Button type="link" style={{ padding: 0, height: 'auto', color: 'rgba(255, 255, 255, 0.85)', textAlign: 'left', justifyContent: 'flex-start' }}>Contact Us</Button>
        <Button type="link" style={{ padding: 0, height: 'auto', color: 'rgba(255, 255, 255, 0.85)', textAlign: 'left', justifyContent: 'flex-start' }}>support@mydancehall.com</Button>
      </div>
    </Col>

    {/* Contact Section */}
    <Col xs={24} sm={12} md={6}>
      <Title level={3} style={{ 
        marginBottom: '24px', 
        textAlign: 'left',
        fontSize: '20px',
        color: 'white',
        '@media (min-width: 768px)': {
          fontSize: '24px'
        }
      }}>Contact</Title>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: '16px',
        fontSize: '14px',
        color: 'rgba(255, 255, 255, 0.85)',
        '@media (min-width: 768px)': {
          fontSize: '16px'
        }
      }}>

        

        

        <Text style={{ color: 'rgba(255, 255, 255, 0.85)' }}>205 Narayana Vihar Apartments, Nehrunagar, Tirupati</Text>        
        <Text style={{ color: 'rgba(255, 255, 255, 0.85)' }}>Andhra Pradesh, 517501</Text>
        <Text style={{ color: 'rgba(255, 255, 255, 0.85)' }}>India</Text>
        
      </div>
    </Col>

    {/* Follow Us Section */}
    <Col xs={24} sm={12} md={6}>
      <Title level={3} style={{ 
        marginBottom: '24px', 
        textAlign: 'left',
        fontSize: '20px',
        color: 'white',
        '@media (min-width: 768px)': {
          fontSize: '24px'
        }
      }}>Follow Us</Title>
      <Space size="large" style={{ textAlign: 'left' }}>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
          <InstagramOutlined style={{ 
            fontSize: '24px', 
            color: 'rgba(255, 255, 255, 0.85)',
            '@media (min-width: 768px)': {
              fontSize: '28px'
            }
          }} />
        </a>
        <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
          <YoutubeOutlined style={{ 
            fontSize: '24px', 
            color: 'rgba(255, 255, 255, 0.85)',
            '@media (min-width: 768px)': {
              fontSize: '28px'
            }
          }} />
        </a>
      </Space>
    </Col>
  </Row>

  {/* Copyright Section */}
  <Row justify="center" style={{ 
    marginTop: '40px',
    '@media (min-width: 768px)': {
      marginTop: '60px'
    }
  }}>
    <Col>
      <Divider style={{ margin: '24px 0', borderColor: 'rgba(255, 255, 255, 0.15)' }} />
      <Text style={{ 
        color: 'rgba(255, 255, 255, 0.45)',
        fontSize: '14px',
        '@media (min-width: 768px)': {
          fontSize: '16px'
        }
      }}>
        DanceHall ©2025 All Rights Reserved
      </Text>
    </Col>
  </Row>
</Footer>
</Content>
      
    </Layout>
  );
};

export default Public;
